.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
  
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

th {
  cursor: pointer;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
}

.react-bs-table-sizePerPage-dropdown {
  display: none;
}

.form-control:disabled {
  background-color: #e9ecef;
}

.right {
  float: right;
}

.cmr-10 {
  margin-right: 5px;
}

.search-label {
  float: right;
  margin: 10px;
}

.table-caption {
  text-align: right;
}

.table-caption h1 {
  color: #fff;
  margin: 20px 20px 0 0;
}

.add-btn {
  margin: 10px;
}

tr {
  cursor: pointer
}

.react-bootstrap-table-pagination {
  margin-top:  15px;
}

.react-bootstrap-table-pagination-total {
  margin-left: 15px;
}

.pagination {
  margin-right: 25px;
}

.loader {
  opacity:0.9;
  background-color:#ccc;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:1000;
}

.loader img {
  position: absolute;
  left: 45%;
  top: 30%
}

.inline {
  margin-left: 40px !important;
}