.add-btn {
    margin: 10px;
}

.image-container .deleteImage , .uploadIcon, .label, .chooseFileButton {
    display: none;
}

.image-container-add .deleteImage , .image-container-add .uploadIcon, .image-container-add .label, .image-container-add .chooseFileButton {
    display: block;
}

.uploadPicturesWrapper {
    max-height: 700px;
    overflow-y: scroll;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

.inline {
    display: inline !important;
}

.configs {
    color: seagreen !important
}

.orange {
    color: #212121 !important
}

.max-width {
    max-width: 500px !important;
}

.Approved {
    color: seagreen !important;
}

.Disapproved {
    color: red !important;
}

.Pending {
    color: orange !important;
}