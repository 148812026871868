.hardware-icon {
    width: 2rem;
    height: 2rem;
    margin-left: 10px;
    cursor: pointer;
}

.add-tab {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
}

.icon {
    width: 2rem;
    height: 2rem;
}