.MuiExpansionPanel-root.Mui-expanded {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.MuiExpansionPanel-rounded {
    /* margin-bottom: 2px !important; */
    /* margin-top: 2px !important; */
    margin-bottom: 5px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    /* border-bottom: 0.9px solid lightgrey; */
}

.react-tabs__tab {
    margin: 1px;
    /* color: red !important; */
}

.react-tabs__tab--selected {
    font-weight: bold;
}