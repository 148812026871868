.custom-icon {
  width: 2rem;
  height: 1rem;
}

/* .grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196F3;
  padding: 10px;
} */

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 10px;
  padding: 14px;
  background-color: rgb(248, 248, 248);  

}

.grid-item {
  background-color: rgb(255, 255, 255);
  padding: 12px;
  font-size: 30px;
  text-align: center;
}

.inline-print {
  display: inline !important;
  float: left !important;
  /* margin-left: 100px; */
}

.marginTop {
  margin-top: 10px;
}

h6 {
   line-height: 0.5rem;
}