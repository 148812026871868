.grey {
    padding: 1.25rem;
    border: 1px solid #e6ecf1;
    border-radius: .25rem;
    background-color: #f5f7f9;
    margin-bottom: 15px;
}

.d-btn {
    margin-top: -15px !important;
    margin-right: -10px !important;
}