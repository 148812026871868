.hardware-icon {
    width: 2rem;
    height: 2rem;
    margin-left: 10px;
    cursor: pointer;
}

.add-tab {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
}

.rem-icon {
    width: 5px !important;
    height: 5px !important;
}

.rem-icon i {
    font-size: 15px !important;
}