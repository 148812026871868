.add-btn {
  margin: 10px;
}

.image-container .deleteImage,
.uploadIcon,
.label,
.chooseFileButton {
  display: none;
}

.image-container-add .deleteImage,
.image-container-add .uploadIcon,
.image-container-add .label,
.image-container-add .chooseFileButton {
  display: block;
}

.uploadPicturesWrapper {
  max-height: 700px;
  overflow-y: scroll;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.add-btn {
  z-index: 99999;
}

.custom-datatable .active {
  background-color: seagreen !important;
  border: 1px solid seagreen !important;
}

body {
  white-space: pre-wrap;
}

.startDate {
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
}

.endDate {
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
}