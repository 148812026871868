  .graph-shimmer {
    margin-top: 25px;
    width: 230px;
    height: 100%;
    padding: 15px;
    min-height: 87vh;
  }

  .pi-shimmer {
    max-width: 390px;
    max-height: 100%;
    padding: 15px;
    max-height: 87vh;
  }
  
  .rectangular-box-oneg {
    width: 89em;
    height: 5em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    margin-right: 15px;
  }

  .rectangular-box-onepi {
    width: 26em;
    height: 5em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
  }

  .rectangular-box-twog {
    width: 89em;
    height: 15em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 3em;
    margin-right: 15px;
  }

  .rectangular-box-threeg {
    width: 89em;
    height: 30em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    margin-right: 15px;
  }

  .rectangular-box-onep {
    max-width: 14.5em;
    height: 20em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }

  .rectangular-box-onetb {
    width: 58em;
    height: 5em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    margin-right: 15px;
  }
  
  .rectangular-box-twotb {
    width: 58em;
    height: 15em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 3em;
    margin-right: 15px;
  }
  
  .rectangular-box-threetb {
    width: 89em;
    height: 30em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    margin-right: 15px;
  }

  .rectangular-boxt {
    width: 86em;
    height: 3em;
    background-color: whitesmoke;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    margin-right: 15px;
  }
  
.shimmer-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.09) 50%,
      rgba(0, 0, 0, 0.1) 100%
    );
    background-size: 200% auto;
    animation: shimmer 2s linear infinite;
    transform-origin: center;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      background-position: 200% 0;
      transform: scale(1);
    }
  }